<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席“发现美”量化私募交流会
              </p>
              <div class="fst-italic mb-2">2023年4月4日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >量化私募交流会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/32/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技作为特邀算法服务商出席“发现美”量化私募机构专场交流会暨“潜龙杯”开赛和算法科技赋能量化交易活动方案。交流会上，非凸科技首席架构师乔丹从技术的角度深度分享了非凸算法如何赋能量化交易，引发了激烈讨论。与此同时，量化管理人及资方也对非凸算法投来高度关注，提出问题，交流问题，共同探讨了机构业务合作新模式。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/32/02-演讲.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    算法交易作为量化交易的一类工具，在机构交易中发挥着越来越重要的作用。为了满足机构客户在算法方面的业务需求，非凸科技基于人工智能、大数据、云计算及研发能力开发了一系列算法产品，还成立了核心策略研发团队，专注于机器学习领域高频策略研发迭代，不断提高自身算法能力的同时，也提升了机构业务服务能力。
                  </p>
                  <p>
                    非凸科技还通过软硬件结合的技术创新模式，在算法交易场景中实现了速度上的创新。1毫秒的落后就有可能导致交易策略的失效，对此非凸科技在算力等研发投入上每年超千万，最大程度地保障数据的安全性，更加专业地提升算法的有效性，从而极大地满足机构客户对交易速度的极致要求。除此之外，非凸科技推行工程师文化，金融素养和工程实力雄厚，每一次通力协作，都为算法带来了显著提升。
                  </p>
                  <p>
                    目前，非凸科技服务近百家机构客户，上线十余家券商。领先的市场绩效、极致的响应速度、优质的服务质量以及专业的算法能力，均获得券商、量化管理人及资方的高度认可。在运行服务保障方面，非凸科技拥有超20人专业运维团队，通过完善的监控报警系统保障超算集群服务端的正常运行，并及时响应异常情况，快速协调解决。
                  </p>
                  <p>
                    加大对机构客户服务力度，提高综合服务水平，已经被越来越多券商摆在重要的战略位置上。与此同时，也加速了券商与第三方算法服务商的合作，在行情、交易、算法、策略等方面需要引入更多科技力量。对此，非凸科技可以为券商机构提供更好的科技工具，从技术角度全方位保障机构业务的顺利开展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/32/03-乔丹.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技将结合自身在算法交易领域的资源优势，围绕机构客户全生命周期业务需求，寻求技术突破，业务模式创新，持续助力券商提升自身核心竞争力，为金融科技化转型贡献力量。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News32",
};
</script>

<style></style>
